import { Badge } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tooltip from "../Tooltip";

export interface Props {
  value: number | React.ReactNode;
  variant: "neutral" | "info" | "success" | "negative" | "warning" | "honors";
  variantBadge?: "dot" | "standard";
  type: "Subtle" | "Colorful";
  children: React.ReactNode;
  tooltipMessage?: string;
  invisible?: boolean;
  cursorPointer?: boolean;
  onClick?: () => void;
}

interface PillClass {
  name: string;
  styleSubtle: string;
  styleColorful: string;
}

const badgeProps: Array<PillClass> = [
  {
    name: "neutral",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-gray-10 bg-gray-1",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white bg-gray-9"
  },
  {
    name: "info",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-info-9 bg-default-info-3",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white bg-default-info-9"
  },
  {
    name: "success",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-success-9 bg-default-success-3",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white bg-default-success-9"
  },
  {
    name: "negative",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-error-9 bg-default-error-3",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white bg-default-error-9"
  },
  {
    name: "warning",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-warning-9 bg-default-warning-2",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white bg-default-warning-8"
  },
  {
    name: "honors",
    styleSubtle:
      "normal-case font-sans text-xs font-medium text-default-warning-9 bg-[#efb810]",
    styleColorful:
      "normal-case font-sans text-xs font-medium text-default-white bg-[#efb810]"
  }
];

function Pill({
  value,
  variant,
  type,
  children,
  tooltipMessage = "",
  invisible = false,
  variantBadge = "dot",
  cursorPointer = false,
  onClick = () => {
    /* parent should handle this */
  }
}: Props) {
  const [badgeStyle, setBadgeStyle] = useState<string>("");

  const handleStyleChange = () => {
    const style = badgeProps.find((item) => item.name === variant);
    if (style) {
      if (type === "Subtle") {
        setBadgeStyle(style.styleSubtle);
      } else {
        setBadgeStyle(style.styleColorful);
      }
    }
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, type]);

  return (
    <Tooltip message={tooltipMessage || ""} placement="top-end">
      <Badge
        badgeContent={value}
        classes={{ badge: badgeStyle }}
        invisible={invisible}
        variant={variantBadge}
        className={`w-full h-full ${cursorPointer ? "cursor-pointer" : ""}`}
        onClick={onClick}
      >
        {children}
      </Badge>
    </Tooltip>
  );
}

export default Pill;
